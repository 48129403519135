import antdData from 'antd/lib/locale-provider/zh_CN'
import localeData from 'react-intl/locale-data/zh'

const messages = {
  'company.longName': 'Cyber Intelligence Platform',

  'navbar.search': 'Search...',
  'navbar.profileName': 'Anonymous',
  'navbar.profileMenu.role': 'Role',
  'navbar.profileMenu.email': 'Email',
  'navbar.profileMenu.phone': 'Phone',
  'navbar.profileMenu.editProfile': 'Edit Profile',
  'navbar.profileMenu.logout': 'Logout',
  'navbar.profileMenu.login': 'Login',
  'navbar.profileMenu.signup': 'Signup',

  'landing.technology': 'Technology',
  'landing.contact': 'Contact',
  'landing.products': 'Products',
  'landing.services': 'Services',
  'landing.productsPending': 'Future Products',
  'landing.about': 'About',
  'landing.apps': 'Apps',
  'landing.alerts': 'Alerts',
  'landing.api': 'API',

  'products.comint': 'Communications Intelligence',
  'products.dnint': 'Digital Network Intelligence',
  'products.snint': 'Social Network Intelligence',
  'products.imint': 'Imagery Intelligence',
  'products.humint': 'Human Intelligence',
  'products.geoint': 'Geospatial Intelligence',
  'products.sigint': 'Signals Intelligence',
  'products.services': 'Software Development',

  'tech.wai': 'Web Artificial Intelligence (WAI)',
  'tech.search': 'Object Search Engine (OSE)',
  'tech.orchestration': 'Orchestration Engine',

  'footer.company': 'Company',
  'footer.support': 'Support',
  'footer.contact': 'Contact',
  'footer.privacy': 'Privacy',
  'footer.terms': 'Terms',
  'footer.copyrightText': 'Copyright © 2020. All rights reserved',

  'apps.title.applications': 'Applications',
  'apps.domains': 'Domain',
  'apps.domains.msg': 'Search domain information',
  'apps.search': 'Search',
  'apps.search.msg': 'Searching for intelligence',
  'apps.threats': 'Threats',
  'apps.threats.msg': 'Search threat indicators',

  // Accounts
  'apps.account': 'Account',
  'apps.account.msg': 'Account management',
  'apps.account.profile': 'Profile',
  'apps.account.projects': 'Projects',
  'apps.account.applications': 'Applications',
  'apps.account.orgs': 'Organizations',
  'apps.account.api': 'API Access',
  'apps.account.security': 'Security',
  'apps.account.billing': 'Billing',
  'apps.account.billing.payments': 'Payment Methods',
  'apps.account.billing.subscriptions': 'Subscriptions',
  'apps.account.billing.invoices': 'Invoices',
  'apps.account.settings': 'Settings',

  // ...
  'apps.breaches': 'Breaches',
  'apps.breaches.msg': 'View data breaches and related information',
  'apps.privacy': 'Privacy',
  'apps.privacy.msg': 'Check your online privacy footprint.',
  'apps.leaks': 'Leaks',
  'apps.leaks.msg': 'View data leaks and sources.',
  'apps.deadDrop': 'DeadDrop',
  'apps.deadDrop.msg': 'Anonymous Uploads',
  'apps.people': 'People',
  'apps.people.msg': 'Search people',
  'apps.groups': 'Groups',
  'apps.groups.msg': 'Search groups',
  'apps.news': 'Search News',
  'apps.news.msg': 'View historical news articles',

  'auth.login': 'Login',
  'auth.register': 'Register',
  'auth.rememberMe': 'Remember me',
  'auth.forgotPassword': 'Forgot password',

  'auth.unauthorized': 'Unauthorized Access',
  'auth.unauthorized.msg': 'You have no rights to access this page!',

  '404.title': 'Page not found',
  '404.msg': 'The page is deprecated, deleted, or does not exist at all',
  '404.buttonText': 'Go back to the home page',
}

export default {
  locale: 'zh-CN',
  antdData,
  localeData,
  messages,
}
