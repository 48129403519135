import React from 'react'
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
// import { ConnectedRouter } from 'connected-react-router'
import Loadable from 'react-loadable'

import Loader from 'components/layout/Loader'
import NotFoundPage from 'views/404'

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const routes = [
  // Business Pages
  {
    path: '/company',
    component: loadable(() => import('views/Landing')),
    exact: true,
  },
  {
    path: '/terms',
    component: loadable(() => import('views/Terms')),
    exact: true,
  },
  {
    path: '/privacy',
    component: loadable(() => import('views/Privacy')),
    exact: true,
  },

  // Applications
  {
    path: '/apps/search',
    component: loadable(() => import('apps/search')),
  },
  {
    path: '/apps/account',
    component: loadable(() => import('apps/account')),
  },
  {
    path: '/apps/news',
    component: loadable(() => import('apps/news')),
  },

  // {
  //   path: '/apps/deaddrop',
  //   component: loadable(() => import('apps/deaddrop')),
  //   exact: true,
  // },
  // {
  //   path: '/apps/files',
  //   component: loadable(() => import('apps/files')),
  //   exact: true,
  // },
]

class Router extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route key="root" exact path="/" render={() => <Redirect to="/company" />} />
          {routes.map(route => (
            <Route
              path={route.path}
              component={route.component}
              key={route.path}
              exact={route.exact}
            />
          ))}
          <Route key="NotFound" component={NotFoundPage} />
        </Switch>
      </BrowserRouter>
    )
  }
}

export default Router
