import React, {useContext} from 'react'
import {Avatar, Tooltip, Button, Badge} from 'antd'
import {Link} from 'react-router-dom'
import { LockOutlined } from '@ant-design/icons';

import {UserContext} from 'components/context/UserContext'
import styles from './style.module.scss'

export const AppIcons = props => {
  const {apps, authorized} = useContext(UserContext)

  const {onAuthorized} = props

  return (
    <ul className={styles.appIcons}>
      {apps.map(app => (
        <li key={app.key}>
          <AppIcon authorized={authorized} app={app} onAuthorized={onAuthorized} key={app.key} />
        </li>
      ))}
    </ul>
  )
}

export const AppIcon = props => {
  const {authorized, app, onAuthorized} = props
  const userAuthorized = () => {
    if (!authorized && app.authorized) {
      return false
    }
    return true
  }
  if (userAuthorized()) {
    return (
      <Link to={app.path} key={app.key}>
        <Tooltip placement="bottom" title={app.description}>
          <Avatar shape="circle" size="large" src={app.icon} key={app.key} />
        </Tooltip>
        <div>{app.name}</div>
      </Link>
    )
  }

  return (
    <Button type="link" onClick={onAuthorized} key={app.key} style={{paddingTop: '0px'}}>
      <Tooltip placement="bottom" title={app.description}>
        <Badge count={<LockOutlined style={{color: '#f5222d'}} />} offset={[5, 10]}>
          <Avatar
            shape="circle"
            size="large"
            src={app.icon}
            key={app.key}
            style={{color: '#f5222d', borderWidth: '3', borderColor: '#f5222d' }}
          />
        </Badge>
      </Tooltip>
      <div>{app.name}</div>
    </Button>
  )
}
