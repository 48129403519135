import React, { useContext } from 'react'
import { Menu, Dropdown } from 'antd'

import { LangContext } from 'components/context/Localization'
import styles from './style.module.scss'

// See flags: https://www.alt-codes.net/flags
const LANGUAGES = [
  { id: 'en-US', label: 'English', flag: '🇺🇸 ' },
  { id: 'fr-FR', label: 'Français', flag: '🇫🇷 ' },
  { id: 'es-ES', label: 'Español', flag: '🇪🇸 ' },
  { id: 'ar-EG', label: 'عربى', flag: '🇮🇷 ' },
  { id: 'ru-RU', label: 'Русский', flag: '🇷🇺 ' },
  { id: 'zh-CN', label: '简体中文', flag: '🇨🇳 ' },
]

const LanguageSelector = () => {
  const { language, setLang } = useContext(LangContext)
  const { flag } = LANGUAGES.find(lang => {
    return language === lang.id
  })
  const langMenu = lang => {
    return (
      <Menu
        className={styles.menu}
        selectedKeys={[language]}
        onClick={({ key }) => {
          setLang(key)
        }}
      >
        {lang.map(l => (
          <Menu.Item key={l.id}>
            <span role="img" aria-label={l.label} className="mr-2">
              {l.flag}
            </span>
            {l.label}
          </Menu.Item>
        ))}
      </Menu>
    )
  }
  return (
    <Dropdown overlay={langMenu(LANGUAGES)} trigger={['click']}>
      <a>{flag}</a>
    </Dropdown>
  )
}

export default LanguageSelector
