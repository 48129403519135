import React, { useState, useContext, createContext } from 'react'
import { ConfigProvider } from 'antd'
import { IntlProvider, addLocaleData } from 'react-intl'

import english from 'locales/en-US'
import french from 'locales/fr-FR'
import russian from 'locales/ru-RU'
import chinese from 'locales/zh-CN'
import arabic from 'locales/ar-EG'
import spanish from 'locales/es-ES'
import { UserContext } from './UserContext'

const locales = {
  // Default languages
  en: english,
  fr: french,
  ru: russian,
  zh: chinese,
  ar: arabic,
  es: spanish,

  // Country languages (Lower Case)
  'fr-fr': french,
  'es-es': spanish,
  'ru-ru': russian,
  'zh-cn': chinese,
  'en-us': english,
  'ar-eg': arabic,

  // Country languages
  'fr-FR': french,
  'es-ES': spanish,
  'ru-RU': russian,
  'zh-CN': chinese,
  'en-US': english,
  'ar-EG': arabic,
}

addLocaleData(spanish.localeData)
addLocaleData(english.localeData)
addLocaleData(french.localeData)
addLocaleData(russian.localeData)
addLocaleData(chinese.localeData)
addLocaleData(arabic.localeData)

export const LangContext = createContext()

const LangProvider = ({ children }) => {
  const { locale } = useContext(UserContext)
  const defaultLocale = window.localStorage.getItem('cybint/locale') || locale
  const [language, setLang] = useState(defaultLocale)
  const setLanguage = l => {
    if (l) {
      setLang(l)
      window.localStorage.setItem('cybint/locale', l)
    }

  }
  return (
    <LangContext.Provider value={{ language, setLang: setLanguage }}>
      <ConfigProvider locale={locales[language].antdData}>
        <IntlProvider locale={locales[language].locale} messages={locales[language].messages}>
          {children}
        </IntlProvider>
      </ConfigProvider>
    </LangContext.Provider>
  )
}

export default LangProvider
