import React, { useEffect, useState } from 'react'
import { Layout, Menu, Avatar } from 'antd'
import { MenuOutlined } from '@ant-design/icons'

import AppMenuPanel from 'components/panels/AppPanel'
// import SearchBar from 'components/layout/SearchBar'
import Footer from 'components/layout/Footer'

// import styles from './style.module.scss'

const { Header } = Layout

const AppLayout = ({ children, brandIcon, searchBar }) => {
  const [height, setHeight] = useState(window.innerHeight)
  const updateDimensions = () => {
    setHeight(window.innerHeight)
  }
  useEffect(() => {
    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  const Search = () => {
    if (searchBar) {
      return searchBar
    }
    if (brandIcon) {
      return <Avatar src="/resources/images/logos/logo-mobile.png" style={{ float: 'left' }} />
    }
    return null
  }

  return (
    <Layout style={{ minHeight: height }} theme="light">
      <Header style={{ padding: 0, background: 'transparent' }}>
        <Search />
        <Menu theme="light" mode="horizontal" style={{ background: 'transparent' }}>
          <Menu.Item key="2" style={{ float: 'right', border: '0px' }}>
            <AppMenuPanel>
              <MenuOutlined style={{ fontSize: '18px', color: '#000' }} />
            </AppMenuPanel>
          </Menu.Item>
        </Menu>
      </Header>
      <Layout>{children}</Layout>
      <Footer />
    </Layout>
  )
}

export default AppLayout
