import React from 'react'
import ReactDOM from 'react-dom'
import { InMemoryCache, ApolloClient, ApolloProvider } from '@apollo/client'

import Router from 'router'
import LangProvider from 'components/context/Localization'
import UserProvider from 'components/context/UserContext'
import SearchProvider from 'components/context/SearchContext'

import * as serviceWorker from './serviceWorker'

// app styles
import './global.scss'

const client = new ApolloClient({
  uri: 'http://localhost:4000',
  cache: new InMemoryCache(),
})

ReactDOM.render(
  <ApolloProvider client={client}>
    <UserProvider>
      <LangProvider>
        <SearchProvider>
          <Router />
        </SearchProvider>
      </LangProvider>
    </UserProvider>
  </ApolloProvider>,
  document.getElementById('root'),
)

serviceWorker.register()
