import React, { useState, createContext } from 'react'

export const SearchContext = createContext()

const resultList = [
  {
    gender: 'male',
    name: { title: 'Mr', first: 'Esat', last: 'Mayhoş' },
    email: 'esat.mayhos@example.com',
    nat: 'TR',
  },
  {
    gender: 'female',
    name: { title: 'Ms', first: 'Bella', last: 'Jones' },
    email: 'bella.jones@example.com',
    nat: 'NZ',
  },
  {
    gender: 'female',
    name: { title: 'Miss', first: 'Christina', last: 'Davis' },
    email: 'christina.davis@example.com',
    nat: 'GB',
  },
  {
    gender: 'male',
    name: { title: 'Mr', first: 'Armin', last: 'Hoksbergen' },
    email: 'armin.hoksbergen@example.com',
    nat: 'NL',
  },
  {
    gender: 'male',
    name: { title: 'Mr', first: 'Leo', last: 'Rajala' },
    email: 'leo.rajala@example.com',
    nat: 'FI',
  },
  {
    gender: 'male',
    name: { title: 'Mr', first: 'Philip', last: 'Williams' },
    email: 'philip.williams@example.com',
    nat: 'CA',
  },
]

const SearchProvider = ({ children }) => {
  const [search, setQuery] = useState({ query: '', types: [], results: resultList })

  return <SearchContext.Provider value={{ ...search, setQuery }}>{children}</SearchContext.Provider>
}

export const SearchConsumer = SearchContext.Consumer
export default SearchProvider
